<div class="flex max-w-full flex-col items-center">
  <div class="w-full">
    <div class="max-w-full p-2">
      <div class="" *ngIf="huddleView">
        <div
          class="about grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-6 md:gap-y-2"
        >
          <div class="col-span-6 md:col-span-6 lg:col-span-3">
            <div class="flex flex-col gap-2">
              <p-card styleClass="p-0">
                <div class="p-3">
                  <div class="pb-3 pt-1">
                    <h2 class="h-level-2">Details</h2>
                  </div>
                  <div class="grow-1 flex flex-col gap-2">
                    <strong class="flex">
                      <span class="mr-2">Participants</span>
                      <p-button
                        (click)="responders('confirmed')"
                        label="See all"
                        styleClass="primary align-text-bottom"
                        [text]="true"
                      ></p-button>
                    </strong>
                    <p-avatarGroup styleClass="mb-3">
                      <p-avatar
                        *ngFor="let rsvp of rsvpedHuddlers"
                        size="large"
                        shape="circle"
                      >
                        <img
                          *ngIf="rsvp?.user?.profilePicUrl"
                          [src]="
                            getProfileImageById(rsvp?.user?.id) | image | async
                          "
                          alt=""
                        />
                        <img
                          *ngIf="!rsvp?.user?.profilePicUrl"
                          src="../assets/images/user-pic-default.svg"
                          alt="default"
                        />
                      </p-avatar>
                    </p-avatarGroup>
                  </div>
                  <div class="grow-1 flex flex-col gap-2">
                    <strong>Hosts and Co-hosts</strong>
                    <p *ngIf="!coHosts.length" class="mb-3">
                      No Hosts and Co-Hosts added
                    </p>
                    <p-avatarGroup styleClass="mb-3" *ngIf="coHosts.length">
                      <p-avatar
                        *ngFor="let host of coHosts"
                        size="large"
                        shape="circle"
                      >
                        <img
                          *ngIf="host?.profilePicUrl"
                          [src]="getProfileImageById(host?.id) | image | async"
                          alt=""
                        />
                        <img
                          *ngIf="!host?.profilePicUrl"
                          src="../assets/images/user-pic-default.svg"
                          alt="default"
                        />
                      </p-avatar>
                    </p-avatarGroup>
                  </div>
                  <div class="mb-2 flex items-center gap-1">
                    <strong>Duration:</strong>
                    <span
                      >{{
                        calculateDuration(
                          huddleView.startDateTime,
                          huddleView.endDateTime
                        )
                      }}
                    </span>
                  </div>
                  <div class="mb-2 flex items-center gap-1">
                    <strong>Activity:</strong>
                    <span>{{ activityMap[huddleView.activity] }}</span>
                  </div>
                  <div class="mb-2 flex items-center gap-1">
                    <strong>Name:</strong>
                    <span>{{ huddleView.name }}</span>
                  </div>
                  <div class="mb-2 flex items-center gap-1">
                    <strong>Privacy:</strong>
                    <span>{{ visibilityMap["" + huddleView.privacy] }}</span>
                  </div>
                  <div class="flex flex-col">
                    <strong>Description:</strong>
                    <div class="break-words" *ngIf="huddleView.description">
                      <app-description-content
                        [contentHTML]="huddleView.description"
                      ></app-description-content>
                    </div>
                    <div *ngIf="!huddleView.description">
                      No description provided
                    </div>
                  </div>
                </div>
              </p-card>
              <p-card>
                <div class="p-3">
                  <h2 class="h-level-2">External URLs</h2>
                </div>
                <div class="p-3 pt-0">
                  <div class="pb-3 pt-1">
                    <div class="mb-3 flex flex-col">
                      <strong>Trial URL: </strong>
                      <a
                        class="primary"
                        [href]="huddleView.externalTrailUrl"
                        target="_blank"
                        >{{ huddleView.externalTrailUrl }}</a
                      >
                    </div>
                    <div class="mb-3 flex flex-col">
                      <strong>Event URL:</strong>
                      <a
                        class="primary"
                        [href]="huddleView.externalEventUrl"
                        target="_blank"
                        >{{ huddleView.externalEventUrl }}</a
                      >
                    </div>
                    <div class="mb-3 flex flex-col">
                      <strong>Photo Album URL:</strong>
                      <a
                        class="primary"
                        [href]="huddleView.externalPhotoAlbumUrl"
                        target="_blank"
                        >{{ huddleView.externalPhotoAlbumUrl }}</a
                      >
                    </div>
                    <div class="flex flex-col">
                      <strong>Communication Channel URL:</strong>
                      <a
                        class="primary"
                        [href]="huddleView.externalCommChannelUrl"
                        target="_blank"
                        >{{ huddleView.externalCommChannelUrl }}</a
                      >
                    </div>
                  </div>
                </div>
              </p-card>
              <p-card *ngIf="isCarpoolAllowed">
                <div class="p-3">
                  <h2 class="h-level-2">Carpools</h2>
                </div>
                <div class="p-3">
                  <div class="pb-3">
                    <div class="mx-0 flex justify-between px-0 md:mx-3 md:px-5">
                      <div
                        class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                      >
                        <span class="text-2xl">{{ totalCarpools }}</span>
                        <span>Carpools</span>
                      </div>
                      <div
                        class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                      >
                        <span class="text-2xl">{{ totalCarpoolers }}</span>
                        <span>Carpoolers</span>
                      </div>
                      <div
                        class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                      >
                        <span class="text-2xl">{{ totalWaiting }}</span>
                        <span>Waiting</span>
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
              <p-card
                *ngIf="huddleView.communityId"
                styleClass="shadow-none border mp-border"
              >
                <div class="flex flex-col p-3">
                  <div class="mb-2 flex items-center justify-between">
                    <div class="mr-3 rounded-md object-cover">
                      <a
                        class="z-0 inline-block min-h-0 w-full min-w-0 rounded-md p-0"
                        tabindex="0"
                        role="link"
                      >
                        <div class="relative inline-block align-bottom">
                          <img
                            *ngIf="!huddleView.communityObj.logoImageId"
                            alt="default"
                            class="h-[80px] w-[80px] rounded-md"
                            src="../assets/images/community-default-logo.svg"
                          />
                          <img
                            *ngIf="huddleView.communityObj.logoImageId"
                            alt="Logo"
                            class="h-[80px] w-[80px] rounded-md"
                            [src]="
                              getLogoUrl('' + huddleView.communityObj.id)
                                | image
                                | async
                            "
                          />
                        </div>
                      </a>
                    </div>
                    <div class="flex flex-1">
                      <div class="flex flex-col">
                        <div class="my-1">
                          <span class="h-level-4 block min-w-0 text-left">{{
                            huddleView.communityObj.name
                          }}</span>
                        </div>
                        <div class="my-1">
                          <span class="h-level-4 block min-w-0 text-left">{{
                            huddleView.communityObj.location.formattedAddress
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <span>
                      <span
                        >{{
                          huddleView.communityObj.statistics.totalMembers
                        }}
                        Members</span
                      >
                    </span>
                    <span class="h-level-2">&nbsp;&#8901;&nbsp;</span>
                    <span>
                      <span>{{
                        visibilityMap[huddleView.communityObj.privacy]
                      }}</span>
                    </span>
                  </div>
                  <div class="flex items-center">
                    <span>
                      <span
                        >{{
                          huddleView.communityObj.statistics.totalPastHuddles
                        }}
                        Past huddles</span
                      >
                    </span>
                    <span class="h-level-2">&nbsp;&#8901;&nbsp;</span>
                    <span>
                      <span
                        >{{
                          huddleView.communityObj.statistics
                            .totalUpcomingHuddles
                        }}
                        Upcoming huddles</span
                      >
                    </span>
                  </div>
                  <div class="mt-4 flex items-center justify-end">
                    <div class="mr-2">
                      <button
                        type="button"
                        pButton
                        (click)="
                          redirect([
                            '/community',
                            'view',
                            '' + huddleView.communityObj.id,
                          ])
                        "
                        class="mp-button mp-button-filled truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                      >
                        <span>View Community</span>
                      </button>
                    </div>
                    <div>
                      <button
                        pButton
                        type="button"
                        class="mp-button mp-button-outlined truncate rounded-md px-5 py-2 transition-all lg:mb-0 lg:w-auto"
                      >
                        <span class="material-icons prevent"> more_horiz </span>
                      </button>
                    </div>
                  </div>
                </div>
              </p-card>
              <p-card>
                <div class="p-3">
                  <h2 class="h-level-2">Additional Information</h2>
                </div>
                <div class="p-3">
                  <div class="pb-3 pt-1">
                    <div class="mb-2 flex gap-1">
                      <strong>Payment: </strong>
                      <span *ngIf="huddleView.paymentMode === 'FREE'">{{
                        paymentMode["" + huddleView.paymentMode]
                      }}</span>
                      <span *ngIf="huddleView.paymentMode === 'PAID'"
                        >{{ paymentMode["" + huddleView.paymentMode] }} ($
                        {{ huddleView.paymentAmount }})</span
                      >
                    </div>
                    <div class="mb-2 flex gap-1">
                      <strong>RSVP Start at: </strong>
                      <span>{{
                        huddleView.rsvpStartDateTime
                          | date: "EEEE, MMMM d, y, h:mm a"
                      }}</span>
                    </div>
                    <div class="flex gap-1">
                      <strong>RSVP Ends at: </strong>
                      <span>{{
                        huddleView.rsvpEndDateTime
                          | date: "EEEE, MMMM d, y, h:mm a"
                      }}</span>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>
          </div>

          <div class="col-span-6 md:col-span-6 lg:col-span-3">
            <div class="flex flex-col gap-2">
              <p-card>
                <div
                  id="gmap-about"
                  class="gmap-about h-[250px] rounded-md"
                ></div>
                <div class="p-3">
                  <div class="pb-3 pt-1">
                    <div
                      class="mb-4 flex flex-col gap-1"
                      *ngIf="huddleView?.venue"
                    >
                      <span class="font-semibold">Venue: </span>
                      <div>
                        <p>{{ huddleView.venue?.name }}</p>
                        <p>{{ huddleView.venue?.formattedAddress }}</p>
                      </div>
                      <div *ngIf="huddleView.venueNote">
                        <span class="block font-semibold">Venue Note: </span>
                        <span
                          [innerHtml]="
                            huddleView.venueNote.length > 100
                              ? huddleView.venueNote.slice(
                                  0,
                                  huddleView.venueNote.length / 6
                                )
                              : huddleView.venueNote
                          "
                        ></span>
                        <span
                          *ngIf="
                            !showFullVenueNote &&
                            huddleView.venueNote.length > 100
                          "
                          >...</span
                        >
                        <span
                          *ngIf="
                            showFullVenueNote &&
                            huddleView.venueNote.length > 100
                          "
                          [innerHtml]="
                            huddleView.venueNote.slice(
                              huddleView.venueNote.length / 6 + 1,
                              huddleView.venueNote.length
                            )
                          "
                        ></span>
                        <span
                          class="primary cursor-pointer"
                          *ngIf="huddleView.venueNote.length > 100"
                          (click)="showFullVenueNote = !showFullVenueNote"
                          >{{ !showFullVenueNote ? "more" : "less" }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="mb-4 flex flex-col gap-1"
                      *ngIf="huddleView?.meetingPoint"
                    >
                      <span class="font-semibold">Meeting Point: </span>
                      <div>
                        <p>{{ huddleView.meetingPoint?.name }}</p>
                        <p>{{ huddleView.meetingPoint?.formattedAddress }}</p>
                      </div>
                      <div *ngIf="huddleView.meetingPointNote">
                        <span class="block font-semibold">Meeting Note: </span>
                        <span
                          [innerHtml]="
                            huddleView.meetingPointNote.length > 100
                              ? huddleView.meetingPointNote.slice(
                                  0,
                                  huddleView.meetingPointNote.length / 6
                                )
                              : huddleView.meetingPointNote
                          "
                        ></span>
                        <span
                          *ngIf="
                            !showFullMeetingNote &&
                            huddleView.meetingPointNote.length > 100
                          "
                          >...</span
                        >
                        <span
                          *ngIf="
                            showFullMeetingNote &&
                            huddleView.meetingPointNote.length > 100
                          "
                          [innerHtml]="
                            huddleView.meetingPointNote.slice(
                              huddleView.meetingPointNote.length / 6 + 1,
                              huddleView.meetingPointNote.length
                            )
                          "
                        ></span>
                        <span
                          class="primary cursor-pointer"
                          *ngIf="huddleView.meetingPointNote.length > 100"
                          (click)="showFullMeetingNote = !showFullMeetingNote"
                          >{{ !showFullMeetingNote ? "more" : "less" }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex flex-col gap-1"
                      *ngIf="huddleView?.parking"
                    >
                      <span class="font-semibold">Parking Point: </span>
                      <div>
                        <p>{{ huddleView.parking?.name }}</p>
                        <p>{{ huddleView.parking?.formattedAddress }}</p>
                      </div>
                      <div *ngIf="huddleView.parkingNote">
                        <span class="block font-semibold">Parking Note: </span>
                        <span
                          [innerHtml]="
                            huddleView.parkingNote.length > 100
                              ? huddleView.parkingNote.slice(
                                  0,
                                  huddleView.parkingNote.length / 6
                                )
                              : huddleView.parkingNote
                          "
                        ></span>
                        <span
                          *ngIf="
                            !showFullParkingNote &&
                            huddleView.parkingNote.length > 100
                          "
                          >...</span
                        >
                        <span
                          *ngIf="
                            showFullParkingNote &&
                            huddleView.parkingNote.length > 100
                          "
                          [innerHtml]="
                            huddleView.parkingNote.slice(
                              huddleView.parkingNote.length / 6 + 1,
                              huddleView.parkingNote.length
                            )
                          "
                        ></span>
                        <span
                          class="primary cursor-pointer"
                          *ngIf="huddleView.parkingNote.length > 100"
                          (click)="showFullParkingNote = !showFullParkingNote"
                          >{{ !showFullParkingNote ? "more" : "less" }}</span
                        >
                      </div>
                    </div>
                    <div
                      *ngIf="
                        !huddleView?.parking &&
                        !huddleView?.meetingPoint &&
                        !huddleView?.venue
                      "
                    >
                      No Location Selected
                    </div>
                  </div>
                </div>
              </p-card>
              <p-card styleClass="p-0">
                <div class="flex justify-between p-3">
                  <h2 class="h-level-2">Responders</h2>
                  <p-button
                    (click)="responders('confirmed')"
                    label="See all"
                    styleClass="primary"
                    [text]="true"
                  ></p-button>
                </div>
                <div
                  class="mx-0 flex justify-between border-b px-0 pb-4 md:mx-3 md:px-5"
                >
                  <div
                    class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                    (click)="responders('confirmed')"
                  >
                    <span class="text-2xl">{{ totalGoing }}</span>
                    <span>Confirmed</span>
                  </div>
                  <div
                    class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                    (click)="responders('tentative')"
                  >
                    <span class="text-2xl">{{ totalMayBe }}</span>
                    <span>Tentative</span>
                  </div>
                  <div
                    class="menu-item mb-1 flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                    (click)="responders('interested')"
                  >
                    <span class="text-2xl">{{ totalNotGoing }}</span>
                    <span>Interested</span>
                  </div>
                </div>
                <div class="p-3">
                  <h2 class="h-level-2">Suggested Invites</h2>
                </div>
                <div class="rounded-md p-3">
                  <ul role="list" class="">
                    <li
                      *ngFor="let following of followings"
                      class="grow-1 flex-stretch ml-12 flex justify-between py-2"
                    >
                      <div class="grow-1 -ml-12 flex">
                        <p-avatar styleClass="mr-2" size="large" shape="circle">
                          <img
                            *ngIf="following.profilePicUrl"
                            [src]="
                              getProfileImageById(following.id) | image | async
                            "
                            alt=""
                          />
                          <img
                            *ngIf="!following.profilePicUrl"
                            src="../assets/images/user-pic-default.svg"
                            alt="default"
                          />
                        </p-avatar>
                        <div class="flex w-[120px] flex-col md:w-auto">
                          <span>{{ following.displayName }}</span>
                          <div class="truncate">
                            <a
                              [routerLink]="['/', following.username]"
                              class="primary transition duration-300"
                              >&commat;{{ following.username }}</a
                            >
                          </div>
                        </div>
                      </div>
                      <button
                        pButton
                        type="button"
                        (click)="onInvite(following.id)"
                        class="mp-button mp-button-filled h-12 justify-center gap-2 rounded-md px-5 py-2 leading-6 transition-all"
                      >
                        <span>Invite</span>
                      </button>
                    </li>
                    <li *ngIf="!followings.length">
                      No users suggested to invites
                    </li>
                  </ul>
                  <div class="px-2 py-2 text-center">
                    <p-button
                      styleClass="primary"
                      label="Show More"
                      (onClick)="showMore()"
                      [text]="true"
                    ></p-button>
                  </div>
                </div>
              </p-card>
              <p-card styleClass="p-0">
                <div class="flex flex-col justify-between p-3">
                  <h2 class="h-level-2">Personal Tips</h2>
                  <p class="my-2">
                    This feature is on the way and will be available very soon.
                    We’re committed to going the extra mile to support your
                    experience!
                  </p>
                </div>
              </p-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  header="Attendees"
  [view]="'RESPONDERS'"
  [huddlers]="rsvpedHuddlers"
  height="75%"
></app-dialog>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center"></p-progressSpinner>
</div>

<p-dialog
  header="Select User"
  [(visible)]="userPickerVisible"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [breakpoints]="{ '360px': '95vw', '960px': '95vw' }"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
  styleClass="discover-communities p-lrb-clear"
>
  <div #inviteUser class="relative mb-2 overflow-hidden transition-[height]">
    <app-user-picker-new
      [ref]="inviteUser"
      [showSave]="false"
      state="Invite"
      [grouping]="false"
      [predict]="true"
      (action)="onInvite($event)"
      [showActionButton]="true"
      [events]="userPickerEvent.asObservable()"
    ></app-user-picker-new>
  </div>
</p-dialog>
